import React, { useEffect, useState, useContext } from "react";
import { Button } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Navigate, useNavigate } from "react-router-dom";
import authApi from "../../api/authApi";
import jwt_decode from "jwt-decode";
import inventoryApi from "../../api/inventoryApi";
import Image from "react-bootstrap/Image";
import { getDownloadRequest } from "../../api/inventoryApiTwo";
import { PATH } from "../../api/pathUrl";
import NotificationAlert from "../common/NotificationAlert/NotificationAlert";

// Use socketContext to get the socket connection
import { SocketContext } from "../../socket";

const Header = (props) => {
  const navigate = useNavigate();
  const [company, setCompany] = useState();
  const [decoded, setDecode] = useState({});
  const [body, setBody] = useState();
  const [sidebar, setSidebar] = useState(false);
  const [modalshow, setModalshow] = useState(false);
  const [refreshedData, setRefreshData] = useState(true);
  const [allNotifications, setAllNotifications] = useState([]);
  const [notificationsCount, setNotificationsCount] = useState(Number(0));
  // use socketContext
  const socket = useContext(SocketContext);

  useEffect(() => {
    async function init() {
      //.log('!localStorage.getItem("token")',!localStorage.getItem("token"))
      if (!localStorage.getItem("token")) navigate("/login");
      else if (localStorage.getItem("token")) {
        setDecode(jwt_decode(localStorage.getItem("token")));
        setCompany(
          jwt_decode(localStorage.getItem("token"))?.companyinfo?.at(0)
        );

        // //.log('inside--->')
        // let files = await inventoryApi.fetchFiles(jwt_decode(localStorage.getItem("token"))?.id);
        // //.log('files-->-',files)
        // if(files){

        // let result1 = await inventoryApi.fetchMyImage();
        // if(result1){

        //   setBody(URL.createObjectURL(result1));
        //   // setBody(localStorage.getItem("myimage"));
        //   localStorage.setItem('myimage',URL.createObjectURL(result1));
        // }

        let result1 = await getDownloadRequest(`${PATH.userPath}myimage`);
        if (result1) {
          setBody(URL.createObjectURL(result1));
          //     // setBody(localStorage.getItem("myimage"));
          localStorage.setItem("myimage", URL.createObjectURL(result1));
        }
      }
      // let files = await SparkApi.fetchFiles(result.id);
      // setFile(files.id)
      // //setSelectedFiles(files);
      // ////.log('FILES', files[0])
      // if (files && files.length > 0) {
      //   let file = files[0];
      //   let downloadresult = await SparkApi.downloadFiles(file.id);
      //   ////.log('result:', downloadresult);
      //   file.body = window.URL.createObjectURL(downloadresult);
      //   ////.log(file.body);
      //   setBody(file.body);
      // }
    }
    init();
  }, []);

  const logout = () => {
    authApi.logout();
    navigate("/login");
  };

  const toggleSidebar = () => {
    if (sidebar === false) {
      setSidebar(true);
      //.log(sidebar);
      document.querySelector("#sidebar").classList.toggle("active");
      // document.querySelector("#minisidebar").classList.toggle("active");
    } else {
      setSidebar(false);
      //.log(sidebar);
      document.querySelector("#sidebar").classList.toggle("active");
      // document.querySelector("#minisidebar").classList.toggle("active");
    }
  };

  const onClick = () => {
    navigate("/profiles");
  };

  useEffect(() => {
    // as soon as the component is mounted, do the following tasks:
    let refershData = async (data) => {
      const result = await inventoryApi.fetchNotifications();
      if (result) {
        setAllNotifications(result);
      } else {
        setAllNotifications([]);
      }
      //.log('data in refreshData function :>> ', data);
    };
    if (refreshedData) {
      setRefreshData(!refershData);
      refershData();
    }

    // subscribe to notification events
    //.log('inside useEffet :>> ');
    if (!modalshow) socket.on("notification_inserted", refershData);

    return () => {
      //.log('inside return :>> ');
      socket.off("notification_inserted", refershData);
    };
  }, [socket]);

  useEffect(() => {
    if (Object.keys(decoded).length > 0 && allNotifications.length > 0)
      setNotificationsCount(
        allNotifications.filter((data) => {
          return data.recipients[decoded.id] === "notviewed";
        }).length
      );
  }, [decoded, allNotifications]);

  return (
    <>
      <Navbar className="header px-2" bg="" expand="lg" variant="">
        <button
          type="button"
          id="sidebarCollapse"
          className="btn btn-primary"
          onClick={toggleSidebar}
        >
          <i className="fas fa-align-left"></i>
        </button>
        <Navbar.Brand href="#home"></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="#" onClick={onClick}>
              <span className="pt-3"><strong>Logged in as</strong></span>{" "}
            </Nav.Link>
            <Nav.Link href="#" className="pt-1" onClick={onClick}>
              {localStorage.getItem("myimage") && (
                <Image
                  variant="top"
                  src={localStorage.getItem("myimage")}
                  className="rounded-circle"
                  // thumbnail
                  style={{
                    width: "25px",
                    height: "25px",
                    textTransform: "capitalize",
                  }}
                ></Image>
              )}
              <span class="ms-3 badge bg-secondary">
                {Object.keys(decoded).length > 0 && decoded.username}
              </span>
            </Nav.Link>
            <Nav.Link href="#" onClick={onClick}>
              <span class="badge bg-success">
                {Object.keys(decoded).length > 0 &&
                decoded?.role?.at(0).name === "SYSADMIN"
                  ? "SUPER_ADMIN"
                  : decoded?.role?.at(0).name}
              </span>
            </Nav.Link>
            <Nav.Link href="#" style={{cursor: "default"}}><strong>Hotel </strong></Nav.Link>
            <Nav.Link href="#" style={{cursor: "default"}}>
              <span class="badge bg-secondary">{company?.companyname}</span>
            </Nav.Link>
          </Nav>

          <Nav className="ml-auto">
            <Nav.Link>
              <Button
                variant="btn btn-primary position-relative"
                title="Notification"
                onClick={() => setModalshow(!modalshow)}
              >
                <i className="fa-solid fa-bell"></i>
                {notificationsCount > 0 && (
                  <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                    {notificationsCount > 9 ? "9+" : notificationsCount}
                    <span class="visually-hidden">unread messages</span>
                  </span>
                )}
              </Button>
            </Nav.Link>
            <Nav.Link>
              {localStorage.getItem("token") ? (
                <Button
                  variant="btn btn-primary"
                  onClick={logout}
                  title="Logout"
                >
                  <i className="fa-solid fa-right-from-bracket"></i>
                </Button>
              ) : (
                <></>
              )}
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>

      {modalshow && (
        <NotificationAlert
          show={modalshow}
          onHide={() => setModalshow(!modalshow)}
          allNotifications={allNotifications}
          fetchNotifications={(data) => {
            setAllNotifications(data);
            if (data && data.length === 0) {
              setNotificationsCount(Number(0));
            }
          }}
        />
      )}
    </>
  );
};

export default Header;
