import React, { useEffect, useState } from "react";
import { Col, Container, Row, ToastContainer } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-toastify/dist/ReactToastify.css";
import * as formik from "formik";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { schemaRoomEdit } from "../ValidateSchemaHelper";
import { roomEditInitialValues } from "../InitialValuesHelper";
import { useDispatch,useSelector } from "react-redux";
import { putRequest , postRequest} from "../../api/inventoryApiTwo";
import { PATH , TYPE } from "../../api/pathUrl";

const RoomEdit = (props) => {

  const location = useLocation();
  const navigate = useNavigate();
  let dispatch = useDispatch();
  
  const [booking, setBooking] = useState(location.state? location.state : {});
  const { resultHandle , roomDetail } = useSelector((state) => state.apiReducer);

  //.log("roomDetail --> ",roomDetail)
  //.log("location.state --> ",location.state)

  const handleCancel = () => {
    if (roomDetail.id) navigate("/room/" + roomDetail.id, { state: roomDetail });
    else navigate("/rooms");
  };

  useEffect(() => {
    if (resultHandle.success) {
      navigate(`/room/${resultHandle.data.id}`, { state: resultHandle.data });
      dispatch({ type: 'SET_RESULT_HANDLE', payload: {} })
    }
  }, [resultHandle]) 

  const handleSubmitSave = async (event) => {
    dispatch({ type:  TYPE.SET_ROOM , payload: {} })
    if (roomDetail.id) {
      dispatch(putRequest(PATH.roomPath, TYPE.GET_ALL_ROOMS, location.pathname.split('/')[2], event, false));
    } else {
      dispatch(postRequest(PATH.roomPath, event, TYPE.GET_ALL_ROOMS));
    }
  };

  const { Formik } = formik;

  return (
    <>
      <ToastContainer />
      <Container className="view-form">
        <Row>
          <Col></Col>
          <Col lg={8} style={{backgroundColor : "#fff"}} className="pb-3">
          <Formik
            validationSchema={schemaRoomEdit()}
            onSubmit={handleSubmitSave}
            initialValues={roomEditInitialValues(booking)}
          >
            {({ handleSubmit, handleChange,touched, values, errors,setFieldValue }) => (
            <Form
              className="mt-3"
              onSubmit={handleSubmitSave}
             // noValidate
             // validated={validated}
            >
              <Row className="view-form-header align-items-center"
              style={{
                    marginTop: "-15px",
                  }}>
                <Col lg={3}>
                  {booking.id ? (
                    <>
                      Edit Room
                      <h4>{booking.name}</h4>
                    </>
                  ) : (
                    "New Room"
                  )}
                </Col>
                <Col lg={9} className="d-flex justify-content-end">
                  <Button className="btn-sm mx-2" onClick={handleSubmit}>
                    Save
                  </Button>
                  <Button
                    className="btn-sm"
                    variant="danger"
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <Form.Group className="mx-3" controlId="formBasicFirstName">
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicFirstName"
                      
                    >
                      Name <b class="red-star">*</b>
                    </Form.Label>
                    <Form.Control
                     // required
                      type="text"
                      name="name"
                      placeholder="Enter Name"
                      value={values.name}
                      onChange={handleChange}
                    />
                     {touched.name && <div className="form-error ">
                       {errors.name}
                     </div>
                     }
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group className="mx-3" controlId="formBasicLastName">
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicLastName"
                    >
                      Room No <b class="red-star">*</b>
                    </Form.Label>
                    <Form.Control
                      required
                      type="text"
                      name="roomno"
                      placeholder="Enter Room No"
                      value={values.roomno}
                      onChange={(e) => {
                        setFieldValue(
                          "roomno",
                          (e.target.value).split(' ').join('')
                        );
                      }}
                    />
                     {touched.roomno && <div className="form-error ">
                       {errors.roomno}
                     </div>
                     }
                  </Form.Group>
                </Col>
              </Row>
              <Row>
             
                <Col  lg={6}>
                  <Form.Group className="mx-3" controlId="formBasicPhone">
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicPhone"
                    >
                      Block   <b class="red-star">*</b>
                    </Form.Label>
                    <Form.Select
                      required
                     // aria-label="Enter Payment Status"
                      name="block"
                      value={values.block}
                      onChange={handleChange}
                    >
                      <option value="">--Select--</option>
                      <option value="A">A</option>
                      <option value="B">B</option>
                      <option value="C">C</option>
                      <option value="D">D</option>
                      <option value="E">E</option>
                      <option value="F">F</option>
                      <option value="G">G</option>

                    </Form.Select>
                    {touched.block && <div className="form-error ">
                       {errors.block}
                     </div>
                     }
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group className="mx-3" controlId="formBasicPhone">
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicPhone"
                    >
                      Floor   <b class="red-star">*</b>
                    </Form.Label>
                    <Form.Select
                      required
                      aria-label="Enter Payment Status"
                      name="floor"
                      value={values.floor}
                      onChange={handleChange}
                    >
                      <option value="">--Select--</option>
                      <option value="0">G</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>
                    </Form.Select>
                    {touched.floor && <div className="form-error ">
                       {errors.floor}
                     </div>
                     }
                  </Form.Group>
                </Col>

              <Col lg={6}>
                  <Form.Group className="mx-3" controlId="formBasicEmail">
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicEmail"
                    >
                      Occupancy <b class="red-star">*</b>
                    </Form.Label>
                    <Form.Select
                      required
                      aria-label="Enter Payment Status"
                      name="occupancy"
                      value={values.occupancy}
                      onChange={handleChange}
                    >
                      <option value="">--Select--</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>
                    </Form.Select>
                    {touched.occupancy && <div className="form-error ">
                       {errors.occupancy}
                     </div>
                     }
                  </Form.Group>
                </Col>

                <Col lg={6}>
                  <Form.Group className="mx-3" controlId="formBasicType">
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicTitle"
                    >
                      Type <b class="red-star">*</b>
                    </Form.Label>
                    <Form.Select
                      required
                      aria-label="Enter Payment Status"
                      name="type"
                      value={values.type}
                      onChange={handleChange}>
                      <option value="">--Select--</option>
                      <option value="With Bed">With Bed</option>
                      <option value="Without Bed">Without Bed</option>
                      {/* <option value="Queen">Queen</option>
                      <option value="King">King</option> */}
                    </Form.Select>
                    {touched.type && <div className="form-error ">
                       {errors.type}
                     </div>
                     }
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group className="mx-3" controlId="formBasicDescription">
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicStreet"
                    >
                      Ac/Non-Ac <b class="red-star">*</b>
                    </Form.Label>
                    <Form.Select
                      required
                      aria-label="Enter Payment Status"
                      name="acnonac"
                      value={values.acnonac}
                      onChange={handleChange}
                    >
                      <option value="">--Select--</option>
                      <option value="AC">AC </option>
                      <option value="Non - Ac">Non - Ac</option>
                    </Form.Select>
                    {touched.acnonac && <div className="form-error ">
                       {errors.acnonac}
                     </div>
                     }
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group className="mx-3" controlId="formBasicDescription">
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicStreet"
                    >
                      Status 
                    </Form.Label>
                    <Form.Select
                      required
                      aria-label="Enter Payment Status"
                      name="status"
                      value={values.status}
                      onChange={handleChange}
                    >
                      <option value="">--Select--</option>
                      <option value="Available">Available </option>
                      <option value="Not Available">Not Available</option>
                    </Form.Select>
                    {touched.status && <div className="form-error ">
                       {errors.status}
                     </div>
                     }
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group className="mx-3" controlId="formBasicDescription">
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicStreet"
                    >
                      Description
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      name="description"
                      placeholder="Enter Description"
                      value={values.description}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Form>
            )}
            </Formik>
          </Col>
          <Col></Col>
        </Row>
      </Container>
    </>
  );
};

export default RoomEdit;
