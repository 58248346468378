import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Link } from "react-router-dom";
import * as constants from "../../constants/CONSTANT";
import jwt_decode from "jwt-decode";
import { useLocation } from "react-router-dom";
const Sidebar = () => {
  const [sidebar, setSidebar] = useState(true);
  const [company, setCompany] = useState();
  const [permission, setPermission] = useState([]);
  const location = useLocation();
  const [decoded, setDecode] = useState();
  useEffect(() => {
    if (localStorage.getItem("token")) {
      setDecode(jwt_decode(localStorage.getItem("token")));
      setCompany(jwt_decode(localStorage.getItem("token"))?.companyinfo.at(0));
      var tempVar =[];
      jwt_decode(localStorage.getItem("token"))?.permissions.forEach(e=>tempVar.push(e.name))
      //.log('permiss',tempVar)
      setPermission(tempVar);
    }
  }, []);
  return (
    <>
      <nav id="sidebar">
        <div className="sidebar-header">
          <center>
            <img src="/logo3.png" style={{ height: "100px" }} />
          </center>
          <center className="pt-2">
            <h5>{company?.companyname}</h5>
          </center>
        </div>

        <ul className="list-unstyled components">
          <li className={`${location.pathname.endsWith("/") ? "active" : ""}`}>
            <Link to="/" /*className="dropdown-toggle"*/>
              {" "}
              <i className="fa-solid fa-house mx-2"></i> <b>Home</b>{" "}
            </Link>
          </li>

          <li
            className={`${
              location.pathname.includes("/dashboard") ? "active" : ""
            }`}
          >
            <Link to="/dashboard">
              <i className="fa-solid fa-dashboard mx-2"></i> <b>Dashboard</b>
            </Link>
          </li>
          
          <li
            className={`${location.pathname.includes("/room") ? "active" : ""}`}
          >
            <Link to="/rooms">
              {" "}
              <i className="fa-solid fa-building mx-2"></i> <b>Rooms</b>
            </Link>
          </li>

          <li
            className={`${
              location.pathname.includes("/booking") ? "active" : ""
            }`}
          >
            <Link to="/bookings">
              {" "}
              <i className="fa-solid fa-book mx-2"></i> <b>Bookings</b>
            </Link>
          </li>
          
          <li
            className={`${
              location.pathname.includes("/AdvanceBooking") ? "active" : ""
            }`}
          >
            <Link to="/AdvanceBooking">
            <i class="fas fa-calendar-alt mx-2"style={{"color":" #fffafa"}}></i> <b>Advance Booking</b>            </Link>
          </li>

          <li
            className={`${
              location.pathname.includes("/contact") ? "active" : ""
            }`}
          >
            <Link to="/contacts">
              {" "}
              <i className="fa-solid fa-address-book mx-2"></i> <b>Contacts</b>
            </Link>
          </li>

          <li>
            {/* <Link to="/bills"><i className="fa-solid fa-receipt mx-2"></i>  <b>Bills</b> </Link> */}
          </li>

          <li
            className={`${
              location.pathname.includes("/report") ? "active" : ""
            }`}
          >
            <Link to="/reports">
              <i className="fa-solid fa-chart-line mx-2"></i> <b>Reports</b>
            </Link>
          </li>

          <li
            className={`${
              location.pathname.includes("/Todo") ? "active" : ""
            }`}
          >
            <Link to="/Todo">
              <i class="fa-solid fa-list mx-2"></i> <b>To-Do</b>
            </Link>
          </li>

          <li
            className={`${
              location.pathname.includes("/paymentsandexpenses") ? "active" : ""
            }`}
          >
            <Link to="/paymentsandexpenses">
              <i class="fa-solid fa-indian-rupee-sign mx-2"></i> <b>Cash-In / Cash-Out</b>
            </Link>
          </li>

          {/* {company  && company.active_features.includes("Agent_Commission") && 
          <li
            className={`${
              location.pathname.includes("/agentcommission") ? "active" : ""
            }`}
          >
            <Link to="/agentcommission">
              <i class="fa-solid fa-dollar-sign mx-2"></i>{" "}
              <b>Agent Commission</b>
            </Link>
          </li>
}
          {company  && company.active_features.includes("Restaurant") && 
          <ul class="nav flex-column" id="nav_accordion">
            <li class="nav-item">
              <a
                class="nav-link"
                data-bs-toggle="collapse"
                data-bs-target="#menu_item1"
                href="#"
              >
                  <i class=" mx-2 fa-regular fas fa-glass-cheers fa-fw" aria-hidden="true"></i>Restaurant <i class="bi small bi-caret-down-fill"></i>
              </a>
              <ul
                id="menu_item1"
                class="submenu collapse "
                style={{listStyleType: "none"}}
                data-bs-parent="#nav_accordion"
              >
                  <li
                className={`${
                  location.pathname.includes("/Category") ? "active" : ""
                }`}
              >
                <Link to="/Category">
                <i  class="fa-solid fa-stream mx-2"></i> <b>Categories</b>
                </Link>
              </li>  
              <li
            className={`${
              location.pathname.includes("/item") ? "active" : ""
            }`}
          >
            <Link to="/items">
            <i  class="fa-solid fa-cutlery mx-2"></i> <b>Items</b>
            </Link>
          </li> 
              </ul>
            </li>
          </ul>} */}
          <li
            className={`${
              location.pathname.includes("/profile") ? "active" : ""
            }`}
          >
            <Link to="/profiles">
              <i className="fa-solid fa-circle-user mx-2"></i> <b>My Profile</b>
            </Link>
          </li>


          <li>
            {/* <Link to="/meetings"><i className="fa-solid fa-calendar-plus mx-2"></i> <b>Calender</b></Link> */}
          </li>

          {decoded?.role?.at(0).name === "ADMIN" || 
          decoded?.role?.at(0).name === "SYSADMIN" ? (
            <li
              className={`${
                location.pathname.includes("/user") ? "active" : ""
              }`}
            >
              <Link to="/users">
                <i className="fa-solid fa-user  mx-2"></i> <b>Users</b>
              </Link>
            </li>
          ) : (
            ""
          )}

          <li>
            <hr style={{ color: "white" }} />
          </li>

          <li>
            {/* <img
              src={company?.logo}
              style={{ width: "80%", borderRadius: "1rem", padding: "1rem" }}
            ></img> */}
          </li>
        </ul>
      </nav>
    </>
  );
};

export default Sidebar;
